var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"E5qkGXJ8cQGuQq5Mqw51w"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://e5365e8bdd1f4e6c8a2a3b2070fa6f70@o4505178388955136.ingest.sentry.io/4505184328286208",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  enabled: process.env.SENTRY_DISABLE ? false : true,
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0
});
